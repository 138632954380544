<template>
  <v-main class="ma-0">
    <BannerCarousel />

    <v-container>
      <Title title="Destaques" />
      <v-row dense>
        <v-col
          v-for="(product, idx) in products"
          :key="idx"
          cols="6"
          md="3"
          xl="2"
        >
          <ProductCard
            :product="product"
            :minHeight="$vuetify.breakpoint.mobile ? 200 : 370"
          />
        </v-col>
      </v-row>

      <Title title="Na magazine Brindes você encontra" />
      <v-card class="elevation-4">
        <v-row>
          <v-col
            v-for="(category, idx) in categories"
            :key="idx"
            align="center"
            cols="6"
            md="2"
            lg="2"
          >
            <v-avatar
              :size="$vuetify.breakpoint.xl ? 180 : 120"
              color="secondary"
            >
              <v-img
                contain
                :src="category.src"
              />
            </v-avatar>
            <h6
              class="primary--text text-subtitle-2 text-lg-h6 font-weight-bold"
            >
              {{ category.title }}
            </h6>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="my-16">
        <v-col
          class="pa-0"
          cols="12"
        >
          <v-card
            flat
            rounded="lg"
            class="pa-0"
          >
            <v-img
              cover
              :src="
                banner_card != null
                  ? $store.state.imagePath + banner_card.image_path
                  : '@/assets/banner02.png'
              "
            />
          </v-card>
        </v-col>
      </v-row>

      <Title title="Novidades da semana" />
      <v-row dense>
        <v-col
          v-for="(product, idx) in products_new"
          :key="idx"
          cols="6"
          md="3"
          xl="2"
        >
          <ProductCard
            :product="product"
            :minHeight="$vuetify.breakpoint.mobile ? 200 : 370"
          />
        </v-col>
      </v-row>

      <v-row class="my-16">
        <v-col
          class="pa-0"
          cols="12"
        >
          <v-card
            flat
            rounded="lg"
            class="pa-0"
          >
            <v-img
              cover
              :src="
                banner_bottom != null
                  ? $store.state.imagePath + banner_bottom.image_path
                  : '@/assets/banner03.png'
              "
            />
          </v-card>
        </v-col>
      </v-row>

      <Title title="Clientes Satisfeitos" />
      <v-slide-group
        v-model="model"
        class="pa-4 mb-15"
        active-class="success"
        show-arrows
      >
        <v-slide-item
          v-for="(item, idx) in clients"
          :key="idx"
          class="mx-2"
        >
          <v-avatar size="120">
            <v-img
              :src="
                item != null
                  ? $store.state.imagePath + item.image_path
                  : '@/assets/banner03.png'
              "
            />
          </v-avatar>
        </v-slide-item>
      </v-slide-group>
    </v-container>
  </v-main>
</template>

<script>
export default {
  components: {
    Title: () => import('@/components/web/layout/widgets/Title'),
    BannerCarousel: () => import('@/components/web/home/BannerCarousel'),
    ProductCard: () => import('@/components/web/layout/widgets/ProductCard'),
  },
  data: () => ({
    model: null,
    products: [],
    products_new: [],
    banner_card: null,
    banner_bottom: null,
    clients: null,
    categories: [
      {
        src: require('@/assets/img01.png'),
        title: 'Canetas',
      },
      {
        src: require('@/assets/img02.png'),
        title: 'Chaveiros',
      },
      {
        src: require('@/assets/img03.png'),
        title: 'Canecas',
      },
      {
        src: require('@/assets/img04.png'),
        title: 'Agendas',
      },
      {
        src: require('@/assets/img05.png'),
        title: 'Camisetas',
      },
      {
        src: require('@/assets/img06.png'),
        title: 'Adesivos',
      },
    ],
  }),
  methods: {
    getNewProducts() {
      this.$api
        .get(`products/site/home`)
        .then((res) => {
          this.products_new = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.selectedImg = this.product.product_images[0].src
          this.product.product_images.map((img) => {
            this.colors.push(img.color)
          })
        })
    },
    getProducts() {
      this.$api
        .get('homes/products')
        .then((res) => {
          this.products = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getClientBanner() {
      this.$api
        .get('homes/client_banner')
        .then((res) => {
          this.banner_bottom = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getClients() {
      this.$api
        .get('homes/clients')
        .then((res) => {
          this.clients = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getBannerCard() {
      this.$api
        .get('homes/card')
        .then((res) => {
          this.banner_card = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    this.$store.commit('setBreadcrumbs', null)
    this.getNewProducts()
    this.getProducts()
    this.getBannerCard()
    this.getClientBanner()
    this.getClients()
  },
}
</script>

<style>
div.v-slide-group__prev i.theme--light.v-icon,
div.v-slide-group__next i.theme--light.v-icon {
  color: rgba(236, 106, 35, 1) !important;
}
div.v-slide-group__prev--disabled i.theme--light.v-icon.v-icon.v-icon--disabled,
div.v-slide-group__next--disabled
  i.theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(236, 106, 35, 0.5) !important;
}
</style>
